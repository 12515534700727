.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.form {
  width: 600px;
  height: 300px;
  background: #FFFFFF;
  overflow: auto;
  padding: 20px;
  position: relative;
  background: rgba(43, 43, 43, 100);
  color: #FFFFFF;
}

.title {
  margin: 0;
  padding-bottom: 20px;
  font-size: 24px;
}

.inputInner {
  padding-top: 10px;
}

.closeBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #E20613;
}

.submitBtn {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;

}
