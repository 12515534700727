.scoreboardStandard {
  background: inherit !important;
  height: fit-content;
  max-width: 1000px;
  padding-left: 40px !important;
  &_header {
    height: 140px;
  }

  &_body {
    max-height: calc(100vh - 170px);
    overflow: auto;
    scroll-behavior: auto;


    #left_score .row {
      padding-right: 125px;

      .score-value {
        margin-left: 120px;
      }
    }

    #right_score .score-value {
      padding-left: 40px;
      margin-left: 80px;
    }
  }
}

.activeGameScorePage {

}
