.overlay {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(43, 43, 43, 0.31);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.form {
  width: 100%;
  background: #2B2B2B;
  height: 100%;
  overflow-y: scroll;
  padding: 30px;
}

.header {
  display: flex;
  column-gap: 40px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.tourName {
  display: flex;
  gap: 20px;
  color: #FFFFFF;
}

.tourColumn {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 246px;
  gap: 5%;
  white-space: nowrap;
  width: max-content;
}

.gameModule {
  display: flex;
  align-items: center;
  color: #FFFFFF;
}


.decor {
  width: 50px;
  height: 76px;
  background: #21ba45;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputWrapper {
  div {
    border-radius: 0 !important;
  }
}

.gameCounter {
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
}

.inputLabel {
  margin-bottom: 0;
}

.round1 {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.round {
  display: flex;
  flex-direction: column;

  .gameModule {
    position: relative;
  }

  .gameModule:before {
    content: "";
    width: 70px;
    height: 1px;
    background: #FFFFFF;
    position: absolute;
    right: 100%;
  }
}

.tourHeader {
  margin-bottom: 40px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 246px;
  gap: 5%;
  white-space: nowrap;
  width: max-content;
}

.headerRound {
  .title {
    width: 100%;
    height: 40px;
    text-align: center;
    background: #21ba45;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.prelimCheck {
  margin-top: 20px;
  color: #FFFFFF;

}

.prelimCheck label {
  color: white !important;
}
