.toursForm {
  width: 600px;
  height: 600px;
  overflow: auto;
  padding: 20px;
  position: relative;
  background: white;
  border-radius: 10px;
}
.temporaryField {
  position: relative;
}
.temporaryMessage {
  position: absolute;
  font-size: 14px;
  color: rgba(255, 0, 0, 0.4);
  left: 25%;
  top: 3px;
  z-index: 1;
}

.tourType {
  display: flex;
  column-gap: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
}