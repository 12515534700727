body::-webkit-scrollbar {
  display: none !important;
}

.page {
  position: relative;
  min-height: 100vh;
}

.pageTitle {
  position: relative;
}

.background {
  display: block;
  height: 100%;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
}

.container {
  position: relative;
  padding-top: 20px;
  padding-bottom: 40px;
  height: 100%;

  .titleContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    padding-bottom: 30px;

    .titleDate {
      color: #BF9C30;
      text-transform: capitalize;
      font-family: "DharmaGothicM-ExBold", sans-serif;
      font-size: 60px;
      margin-top: 0;
      margin-bottom: 0;
    }

    .titleGroup {
      text-transform: uppercase;
      font-family: "DharmaGothicM-ExBold", sans-serif;
      font-size: 140px;
      margin-top: 10px;
      line-height: 80px;
    }
  }

  .decorContent {
    margin-top: 20px;
    display: flex;
    column-gap: 10px;

    .decor1 {
      width: 20px;
      height: 4px;
      background-color: #BF9C30;
    }

    .decor2 {
      width: 15px;
      height: 4px;
      background-color: #BF9C30;
    }

    .decor3 {
      width: 10px;
      height: 4px;
      background-color: #BF9C30;
    }

    .decor4 {
      width: 7px;
      height: 4px;
      background-color: #BF9C30;
    }
  }

  .fixtures {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    row-gap: 30px;
  }

  .logo {
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 120px;
    height: 50px;
    background-size: cover;
    background-position: center;
  }
}

.downloadBlock {
  position: fixed;
  top: 20px;
  right: 0;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;

  .iconBlock {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .icon {
    color: white;
    cursor: pointer;
  }
}

.downloadBlock:hover .iconBlock {
  display: block;
  opacity: 1;
}


.finalBlock {
  padding-top: 100px;
  display: grid;
  grid-template-rows: 120px 120px;
  align-items: start;
  justify-content: start;
  padding-left: 60px;
}

.fixturesContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.fixturesContentFinal {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.separator {
  position: absolute;
  top: 71%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 450px;
  background-color: #FFFFFF;
}

.separatorByGroup {
  position: absolute;
  top: 170%;
  left: 50%;
  width: 6px;
  height: 450px;
  background-color: #FFFFFF;
  z-index: 100;
}

.finalFixture {
  padding-top: 80px;
}

.goldTitle {
  color: #BF9C30;
  font-size: 30px;
  font-family: "Sequel100Black75", sans-serif;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  letter-spacing: 2px;
  position: absolute;
  top: 75%;
  left: 53%;
}

.semiFinalTitle {
  color: white;
  font-size: 30px;
  font-family: "Sequel100Black75", sans-serif;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  letter-spacing: 2px;
  position: absolute;
  top: 49%;
  left: 53%;

}

.semiFixtures {
  border-bottom: 3px solid #808385;
  padding-bottom: 30px;

}

.groupsTitle {
  color: #808385;
  font-size: 30px;
  font-family: "Sequel100Black75", sans-serif;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  letter-spacing: 2px;
  position: absolute;
  top: 48%;
  left: 3%;
}

