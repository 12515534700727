.board {
  background: #1a1f25;
  color: #FFFFFF;
  width: 100%;
  padding: 15px;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.infoAndControls {
  width: 50%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.dartsBoard {
  width: fit-content;
  height: fit-content;
}

.playButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statusModal {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionBlockWrapper {
  width: 50%;
}

@media (max-width: 1367px) and (orientation: portrait) {
  .contentWrapper {
    flex-direction: column-reverse;
    align-items: center;
  }
  .infoAndControls {
    flex-direction: column-reverse;
    width: 80%;
    gap: 20px;
  }

}

