.playerBoard {
  position: relative;
  width: 100%;
  height: 200px;
  background: #2e343b;
  padding-left: 40px;
  padding-top: 25px;
  padding-bottom: 15px;
  padding-right: 15px;
}

.activeLine {
  width: 20px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #FFFFFF;
}

.playerScoreRow {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}

.player {
  display: flex;
  align-items: center;
  gap: 20px
}

.icon {
  height: 40px;
  width: 40px;
  color: #FFFFFF;
  filter: invert(1) sepia(1) hue-rotate(-157deg) saturate(5);
}

.playerScore {
  display: flex;
  align-items: center;
  gap: 25px;
}

.legsWon {
  background: #ffeb3b;
  color: #2B2B2B;
  font-weight: bold;
  width: 40px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  padding-top: 2px;
  padding-bottom: 2px;

}

.playerThrowsRow {
  margin-top: 8%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  font-size: 20px;
}

.score {
  width: 25%;
  height: 50px;
  min-width: 60px;
  background: #3b4047;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;

}

.turnScore {
  width: 25%;
  height: 50px;
  min-width: 60px;
  background: transparent;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;

}
@media (max-width: 1367px) and (orientation: portrait) {
  .playerBoard {
    height: 130px;
    background: #2e343b;
    padding-left: 20px;
    padding-top: 25px;
    padding-bottom: 15px;
    padding-right: 15px;
  }
  .playerThrowsRow {
    margin-top: 10px;
  }
  .turnScore {
    width: 22%;
  }
}